//lines
import {
	Box3,
	Float32BufferAttribute,
	InstancedBufferGeometry,
	InstancedInterleavedBuffer,
	InterleavedBufferAttribute,
	Sphere,
	Vector3,
} from '../build/three.module.js';

//to add new methods and attributes
class LineSegmentsGeometry extends InstancedBufferGeometry {

	constructor() {

		super();

		this.type = 'LineSegmentsGeometry';
		const positions = [- 1, 2, 0, 1, 2, 0, - 1, 1, 0, 1, 1, 0, - 1, 0, 0, 1, 0, 0, - 1, - 1, 0, 1, - 1, 0];
		const uvs = [- 1, 2, 1, 2, - 1, 1, 1, 1, - 1, - 1, 1, - 1, - 1, - 2, 1, - 2];
		const index = [0, 2, 1, 2, 3, 1, 2, 4, 3, 4, 5, 3, 4, 6, 5, 6, 7, 5];

		this.setIndex(index);//it sets the triangle index
		this.setAttribute('position', new Float32BufferAttribute(positions, 3));
		this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));

		this.isLineSegmentsGeometry = true;

		this.computeBoundingBox();

		this.computeBoundingSphere();

	}


	applyMatrix4(matrix) {

		const start = this.attributes.instanceStart;
		const end = this.attributes.instanceEnd;

		if (start !== undefined) {

			start.applyMatrix4(matrix);

			end.applyMatrix4(matrix);

			start.needsUpdate = true;

		}

		if (this.boundingBox !== null) {

			this.computeBoundingBox();

		}

		if (this.boundingSphere !== null) {

			this.computeBoundingSphere();

		}
		return this;

	}

	setPositions(array) {//just cipied, check if it is correct

		var lineSegments;

		if (array instanceof Float32Array) {

			lineSegments = array;

		} else if (Array.isArray(array)) {

			lineSegments = new Float32Array(array);

		}

		var instanceBuffer = new InstancedInterleavedBuffer(lineSegments, 6, 1); // xyz, xyz

		this.setAttribute('instanceStart', new InterleavedBufferAttribute(instanceBuffer, 3, 0)); // xyz
		this.setAttribute('instanceEnd', new InterleavedBufferAttribute(instanceBuffer, 3, 3)); // xyz

		//

		this.computeBoundingBox();
		this.computeBoundingSphere();

		return this;

	}

	setColors(array) {

		var colors;

		if (array instanceof Float32Array) {

			colors = array;

		} else if (Array.isArray(array)) {

			colors = new Float32Array(array);

		}

		var instanceColorBuffer = new InstancedInterleavedBuffer(colors, 6, 1); // rgb, rgb

		this.setAttribute('instanceColorStart', new InterleavedBufferAttribute(instanceColorBuffer, 3, 0)); // rgb
		this.setAttribute('instanceColorEnd', new InterleavedBufferAttribute(instanceColorBuffer, 3, 3)); // rgb

		return this;

	}

	fromWireframeGeometry(geometry) {

		this.setPositions(geometry.attributes.position.array);

		return this;

	}

	fromEdgesGeometry(geometry) {

		this.setPositions(geometry.attributes.position.array);

		return this;

	}


	fromMesh(mesh) {

		this.fromWireframeGeometry(new WireframeGeometry(mesh.geometry));

		// set colors, maybe

		return this;

	}

	fromLineSegments(lineSegments) {

		var geometry = lineSegments.geometry;

		if (geometry.isGeometry) {

			this.setPositions(geometry.vertices);

		} else if (geometry.isBufferGeometry) {

			this.setPositions(geometry.attributes.position.array); // assumes non-indexed

		}

		// set colors, maybe

		return this;

	}

	computeBoundingBox() {

		let box = new Box3();


		if (this.boundingBox === null) {

			this.boundingBox = new Box3();

		}

		var start = this.attributes.instanceStart;
		var end = this.attributes.instanceEnd;

		if (start !== undefined && end !== undefined) {

			this.boundingBox.setFromBufferAttribute(start);

			box.setFromBufferAttribute(end);

			this.boundingBox.union(box);

		}



	}

	//should auto execute on creation
	computeBoundingSphere() {

		var vector = new Vector3();



		if (this.boundingSphere === null) {

			this.boundingSphere = new Sphere();

		}

		if (this.boundingBox === null) {

			this.computeBoundingBox();

		}

		var start = this.attributes.instanceStart;
		var end = this.attributes.instanceEnd;

		if (start !== undefined && end !== undefined) {

			var center = this.boundingSphere.center;

			this.boundingBox.getCenter(center);

			var maxRadiusSq = 0;

			for (var i = 0, il = start.count; i < il; i++) {

				vector.fromBufferAttribute(start, i);
				maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(vector));

				vector.fromBufferAttribute(end, i);
				maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(vector));

			}

			this.boundingSphere.radius = Math.sqrt(maxRadiusSq);

			if (isNaN(this.boundingSphere.radius)) {

				console.error('THREE.LineSegmentsGeometry.computeBoundingSphere(): Computed radius is NaN. The instanced position data is likely to have NaN values.', this);

			}

		}



	}

	toJSON() {

		// todo

	}

	applyMatrix(matrix) {

		console.warn('THREE.LineSegmentsGeometry: applyMatrix() has been renamed to applyMatrix4().');

		return this.applyMatrix4(matrix);

	}

}



export {LineSegmentsGeometry};

